import $ from 'jquery';
import * as slick from 'slick-carousel';

let blogCarousel;
let blogCarouselSettings = {
  infinite: true,
  arrows: true,
  nextArrow: '<button type="button" class="slick-next blog-arrow"><span class="sr-only">Next</span></button>',
  prevArrow: '<button type="button" class="slick-prev blog-arrow"><span class="sr-only">Previous</span></button>'
}

let socialCarousel;
let socialCarouselSettings = {
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    nextArrow: '<button type="button" class="slick-next blog-arrow"><span class="sr-only">Next</span></button>',
    prevArrow: '<button type="button" class="slick-prev blog-arrow"><span class="sr-only">Previous</span></button>'
}

let mql = window.matchMedia('(max-width: 1024px)');
// console.log(`mql init matches: ${mql.matches}`);



if(mql.matches) {
    blogCarousel = $('.HomeBlog-posts').slick(blogCarouselSettings);
    socialCarousel = $('.HomeSocialMedia-posts').slick(socialCarouselSettings);
} else {
    if($('.HomeBlog-posts').hasClass('slick-initialized')) {
        blogCarousel.slick('unslick');
        socialCarousel.slick('unslick');
    }
}

mql.addEventListener('change', function (e) {
    console.log(`mql matches: ${e.matches}`);
    if(e.matches) {
        blogCarousel = $('.HomeBlog-posts').slick(blogCarouselSettings);
        socialCarousel = $('.HomeSocialMedia-posts').slick(socialCarouselSettings);
    } else {
        if($('.HomeBlog-posts').hasClass('slick-initialized')) {
            blogCarousel.slick('unslick');
            socialCarousel.slick('unslick');
        }
    }
});

/* window.addEventListener('resize', event => {
    if(window.innerWidth <= 1024) {
        // console.log('mobile');
        // blogCarousel = $('.HomeBlog-posts').slick(blogCarouselSettings);
        // socialCarousel = $('.HomeSocialMedia-posts').slick(socialCarouselSettings);
    } else {
        if($('.HomeBlog-posts').hasClass('slick-initialized')) {
            blogCarousel.slick('unslick');
            socialCarousel.slick('unslick');
        }
    }
});

window.dispatchEvent(new Event('resize')); */

// Remove inline color from timer plugin
$('.timer').find('div').css('color', '');
